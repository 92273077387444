@import '/src/themes/v1/variables';

.Root {
  .Title {
    > span {
      color: $color-primary;
    }
  }

  .Icon {
    background-color: $color-primary;

    &::before {
      background-color: rgba($color-primary, .5);
    }
  }
}