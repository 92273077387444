// Navbar
.navbar-nav .nav-item > a:hover, .navbar-nav .nav-item > a.active {
  color: rgba(white, .5);
  opacity: 1;
}

.sticky .navbar-nav .nav-item a.active, .sticky .navbar-nav .nav-item a:hover {
  color: rgba($color-primary, .5);
}

.sticky .navbar-btn .ud-white-btn {
  background-color: $color-primary;
}

.sticky .navbar-btn .ud-main-btn.ud-login-btn:hover {
  color: rgba($color-primary, .5);
  opacity: 1; 
}

// Banner - Hero
.ud-hero {
  background-color: $color-primary;
}
.ud-hero-buttons .ud-white-btn:hover {
  color: rgba($color-primary, .5);
}

// Button
.ud-main-btn {
  background-color: $color-primary;
}

// Link
.ud-single-feature .ud-feature-link:hover {
  color: $color-primary;
  text-decoration: underline;
}